import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';
import { ChartsReferenceLine, LineSeriesType } from '@mui/x-charts-pro';
import {
  getTooltipDataIndex,
  MuiChartContainerWithLegend,
  tooltipContentProps,
} from 'shared/components/mui-chart/container.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { ChartKeyItem } from 'shared/components/chart-key/chart-key.component';
import { Box } from '@mui/system';
import { nameof } from 'shared/components/datagrid';
import { Grid } from '@mui/material';
import { formatNumber } from 'shared/utils/float-utils';
import { useGetFleetFuelEuGhgIntensityChart } from 'routes/environmental-monitor/services/fleet.service';
import { useContext, useEffect, useState } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';

export type FuelEUGhgIntensityChartProps = {
  date: Date | undefined;
  intensity: number;
  intensityTarget: number;
  complianceBalance: number;
  fuelEUPenalty: number;
};

function customTooltip(
  props: tooltipContentProps,
  chartDataset: FuelEUGhgIntensityChartProps[]
) {
  const index = getTooltipDataIndex(props);

  // if the dataset or the itemData is not available, return null
  if (!chartDataset || index === undefined) return null;
  const data = chartDataset[index];

  return (
    <Grid container sx={{ width: '250px' }}>
      <Grid item xs={10}>
        GHG Intensity Target
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.intensityTarget, 2)}
      </Grid>

      <Grid item xs={10}>
        GHG Intensity
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.intensity, 2)}
      </Grid>

      <Grid item xs={10}>
        Compliance Balance
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.complianceBalance)}
      </Grid>

      <Grid item xs={10}>
        FuelEU Penalty
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {TEXT.CURRENCY.EURO}
        {formatNumber(data?.fuelEUPenalty)}
      </Grid>
    </Grid>
  );
}

export const FleetFuelEUGhgIntensityChart = () => {
  const { imoNumbers, utcDateRange } = useContext(NavBarContext);
  const [chartDataset, setChartDataset] = useState<
    FuelEUGhgIntensityChartProps[]
  >([]);

  const GhgIntensity: ChartKeyItem = {
    id: 1,
    name: 'GHG Intensity',
    fill: '#66BB6A',
  };
  const GhgIntensityTarget: ChartKeyItem = {
    id: 2,
    name: 'GHG Intensity Target',
    fill: '#8C85CF',
  };
  const intensityTarget = 70;
  const fleetFuelEuGhgIntensityChart = useGetFleetFuelEuGhgIntensityChart(
    utcDateRange,
    imoNumbers
  );
  const tempChartDataset = fleetFuelEuGhgIntensityChart.data;
  const loading = fleetFuelEuGhgIntensityChart.loading;

  useEffect(() => {
    if (loading === false && tempChartDataset) {
      const newChartDataset: FuelEUGhgIntensityChartProps[] = Array.from(
        tempChartDataset,
        (p) => {
          return {
            date: p.date.date,
            intensity: p.intensity ?? 0, // Provide a default value of 0 for intensity
            intensityTarget: p.intensityTarget,
            complianceBalance: p.complianceBalance,
            fuelEUPenalty: p.fuelEUPenalty,
          };
        }
      );

      setChartDataset(newChartDataset);
    }
  }, [loading]);

  const bands = [
    { y: 60, color: theme.colors?.eRating },
    { y: 20, color: theme.colors?.bRating },
    { y: 0, color: theme.colors?.aRating },
  ];

  const chartProps = nameof<FuelEUGhgIntensityChartProps>;
  const seriesDate: LineSeriesType[] = [
    {
      type: 'line',
      dataKey: chartProps('intensity'),
      label: GhgIntensity.name,
      highlightScope: { highlighted: 'item', faded: 'global' },
      color: GhgIntensity.fill,
    },
  ];

  const yearFormatter = (date: Date) => {
    return new UTCDate(date).formatMY();
  };

  const yAxisTitle = `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`;

  return (
    <CardContainer title={'FuelEU Greenhouse Gas (GHG) Intensity Trend'}>
      <Box component={'section'} sx={{ pl: 1, mb: 1 }}>
        <MuiChartContainerWithLegend
          background={{ bands: bands }}
          margin={{ right: 40 }}
          dataset={chartDataset}
          series={seriesDate}
          loading={loading}
          error={fleetFuelEuGhgIntensityChart.error}
          tooltip={{
            content: (props) => customTooltip(props, chartDataset),
          }}
          xAxis={[
            {
              dataKey: chartProps('date'),
              tickNumber: chartDataset.length,
              scaleType: 'utc',
              tickLabelStyle: { angle: -35 },
              valueFormatter: yearFormatter,
            },
          ]}
          yAxis={[{ label: yAxisTitle, min: 0 }]}
        >
          <ChartsReferenceLine
            y={intensityTarget}
            label={GhgIntensityTarget.name}
            labelStyle={{ fontSize: '12px' }}
            lineStyle={{ stroke: '#8F80CF', strokeWidth: 2 }}
          />
        </MuiChartContainerWithLegend>
      </Box>
    </CardContainer>
  );
};
