import { ComparisonResults, KpiStatus } from '_gql/graphql';
import {
  IPlannedItinerary,
  IPlannedItineraryDto,
  IPlannedItineraryResults,
  ISequenceDto,
  SequenceTypes,
  SequenceTypesDto,
} from '../models/plannedItinerary.model';
import { IRagFilterData } from 'routes/dashboard/models/fleet-kpis.model';
import { convertKpiStatusToStatus } from '../components/fleet-overview/fleet-grid';
import { EmptyOption } from 'shared/components/inputs/input-fields';
import { PortData } from 'shared/utils/portpolygons-util';
import { NO_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
export class PlannedItineraryResultsMapper {
  static toDomain(
    results: ComparisonResults | undefined,
    plannedItineraryInput: IPlannedItinerary | undefined
  ) {
    if (!results) {
      return undefined;
    }
    return toPlannedItineraryResults(results, plannedItineraryInput);
  }
}

export const toPlannedItineraryResults = (
  results: ComparisonResults,
  plannedItineraryInput: IPlannedItinerary | undefined
) => {
  if (plannedItineraryInput === undefined) {
    return undefined;
  }

  const mappedData = {
    id: results?.id,
    itineraryName: results?.plannedItinerary?.itineraryName,
    departurePort: {
      Port_Name: results?.departurePortName,
      UNLOCODE: '',
    } as PortData,
    arrivalPort: {
      Port_Name: results?.arrivalPortName,
      UNLOCODE: '',
    } as PortData,
    totalDistance: results?.plannedItinerary?.totalDistance,
    totalCO2: results?.plannedItinerary?.totalCO2,
    avgSpeed: results?.plannedItinerary?.averageSpeed,
    avgSpeedVsYtdAvgSpeed:
      results?.plannedItineraryVerseYtdAverageSpeedDifference,
    idlePercentage: results?.plannedItinerary?.idlePercentage,
    idlePercentageVsYtdIdlePercentage:
      results?.plannedItineraryVerseYtdIdleTimePercentage,
    requiredCii: results?.requiredCii,
    attainedCii: results?.plannedItinerary?.estimatedCii,
    cii: {
      value: results?.plannedItinerary?.estimatedCii ?? '---',
      status: convertKpiStatusToStatus(
        results?.plannedItinerary?.ciiStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    ytdCii: results?.ytdCii,
    ciiDelta: results?.plannedItinerary?.ciiDelta,
    ciiDeviation: {
      value: results?.plannedItinerary?.ciiPercentage ?? '---',
      status: convertKpiStatusToStatus(
        results?.plannedItinerary?.ciiStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    estimatedCii: results?.plannedItinerary?.estimatedCii,
    ciiRating: {
      value: results?.plannedItinerary?.ciiRating ?? '---',
      status: convertKpiStatusToStatus(
        results?.plannedItinerary.ciiStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    eoyCii: results?.eoyCii,
    eoyCiiRating: results?.eoyCiiRating,
    eoyCiiDeviation: {
      value: results?.eoyCiiDelta ?? '---',
      status: convertKpiStatusToStatus(
        results?.eoyCiiStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    eoyCiiPercentage: results?.eoyCiiPercentage,

    eeoi: results?.plannedItinerary?.estimatedEeoi,
    eeoiDelta: results?.plannedItinerary?.eeoiDelta,
    eeoiDeviation: {
      value: results?.plannedItinerary?.eeoiPercentage ?? '---',
      status: convertKpiStatusToStatus(
        results?.plannedItinerary?.eeoiStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    eeoiTarget: results?.plannedItinerary?.eeoiTarget,

    eua: results?.plannedItinerary?.estimatedEuaLiability,
    euaDelta: results?.plannedItinerary?.euaDelta,
    euaDeviation: results?.plannedItinerary?.euaPercentage,
    euaPlanned: results?.plannedItinerary?.euaPlanned,
    euaStatus: {
      value: results?.plannedItinerary?.euaPercentage ?? '---',
      status: convertKpiStatusToStatus(
        results?.plannedItinerary?.euaStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    ghgIntensity: results?.plannedItinerary?.ghgIntensity,
    complianceBalance: results?.plannedItinerary?.complianceBalance,
    fuelEUPenalty: results?.plannedItinerary?.fuelEuPenalty,
    ghgStatus: {
      value: results?.plannedItinerary?.ghgIntensity ?? NO_VALUE_INDICATOR,
      status: convertKpiStatusToStatus(
        results?.plannedItinerary?.ghgIntensityStatus ?? KpiStatus.Unknown
      ),
    } as IRagFilterData,
    // original planned itinerary inputs
    plannedItinerary: plannedItineraryInput,
  } as unknown as IPlannedItineraryResults;

  return mappedData;
};

export class PlannedItineraryInputMapper {
  static toDomain(plannedItineraryInput: IPlannedItinerary | undefined) {
    return toPlannedItineraryInput(plannedItineraryInput);
  }
}

export const toPlannedItineraryInput = (
  plannedItineraryInput: IPlannedItinerary | undefined
) => {
  if (!plannedItineraryInput) {
    return undefined;
  }
  const mappedData: IPlannedItineraryDto = {
    id: plannedItineraryInput.id ?? 0,
    name: plannedItineraryInput.itineraryName,
    lastPortOfCall: plannedItineraryInput.lastPortOfCall?.Port_Name ?? '',
    lastPortOfCallCode: plannedItineraryInput.lastPortOfCall?.UNLOCODE ?? '',
    sequences: plannedItineraryInput.sequences.map(
      (sequence) =>
        ({
          id: sequence.id,
          sequenceType: mapSequenceType(sequence.sequence),
          loadingCondition: sequence.loadingCondition,
          portName: sequence.port?.Port_Name,
          portCode: sequence.port?.UNLOCODE,
          distance: Number(sequence.distance ?? 0),
          speed: Number(sequence.speed ?? 0),
          duration: Number(sequence.duration ?? 0),
          fuel: [
            {
              fuelType: sequence.fuelType?.name,
              fuelConsumption: Number(sequence.foc ?? 0),
            },
          ],
          cargoQuantity: Number(sequence.cargoQuantity ?? 0),
        } as ISequenceDto)
    ),
  };

  return mappedData;
};

function mapSequenceType(
  sequenceType: SequenceTypes | EmptyOption | undefined
) {
  switch (sequenceType) {
    case SequenceTypes.AtSea:
      return SequenceTypesDto.AtSea.toString();
    case SequenceTypes.InPortLoading:
      return SequenceTypesDto.InPortLoading.toString();
    case SequenceTypes.InPortDischarge:
      return SequenceTypesDto.InPortDischarge.toString();
    case SequenceTypes.Anchorage:
      return SequenceTypesDto.Anchorage.toString();
    case SequenceTypes.Drifting:
      return SequenceTypesDto.Drifting.toString();
    case SequenceTypes.Maneuvering:
      return SequenceTypesDto.Maneuvering.toString();
    default:
      return '';
  }
}
