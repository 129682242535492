import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVesselKPIs } from 'routes/environmental-monitor/services/vessel.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { sectionContext } from 'shared/components/section/section.component';
import {
  customFooterType,
  KpiCardDivider,
  KpiCardGridContainer,
  RenderKPIGroup,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { formatNumber } from 'shared/utils/float-utils';
import { TEXT } from 'shared/constants/text';
import { typography } from 'styles/typography';
import { emptyKpiData } from 'shared/components/status-kpi-card/status-kpi-card.mock';
import { VesselKPIDataMapper } from 'routes/environmental-monitor/mappers/vessel-kpi-data.mapper';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';
import { EUAChart as EUAMuiChart } from './eua/eua-ratings-mui-chart.component';
import { PieChartStraightAngleMui } from 'shared/components/piechart-straightangle/piechart';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';

export const VesselEUkpi = () => {
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const { data, loading, error } = useGetVesselKPIs(id, utcDateRange);

  const ghgMrvPart2FeatureEnabled = useGetFeatureFlag(FEATURE_FLAG.MRV_PART_2)
    .data?.isEnabled;

  // TODO: Move to view model
  const euaPlannedValue =
    parseInt(data?.euaAgainstPlanned?.tertiaryValue ?? '') || null;
  const hasPlannedValue: boolean = (euaPlannedValue ?? 0) > 0;

  if (data && !hasPlannedValue) {
    data.euaAgainstPlanned.primaryValue = formatNumber(
      data?.euaAgainstPlanned?.secondaryValue,
      0
    );
  }

  const EuaAgainstPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    footerType: 'eua-planned',
    kpiData: data?.euaAgainstPlanned,
    sectionVisibility: { footer: true },
  };

  const EuaAgainstFleetProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Fleet',
    footerType: 'vessel-fleet',
    kpiData: data?.euaAgainstFleet,
    sectionVisibility: { footer: true },
  };

  const EuaNoPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances',
    footerType: 'default-none',
    kpiData: data?.euaAgainstPlanned,
  };

  const GHGIntensityProps: StatusKpiCardProps = {
    title: 'GHG Intensity',
    units: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
    footerUnits: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
    sectionVisibility: { footer: true },
    footerType: [
      {
        title: 'Compliance Balance',
        value: formatNumber(data?.fuelEuGHGIntensity.secondaryValue, 0),
        unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
      },
      {
        title: 'Fuel EU Penalty',
        value:
          TEXT.CURRENCY.EURO +
          ' ' +
          formatNumber(data?.fuelEuGHGIntensity.tertiaryValue, 2),
        unitLabel: '',
      },
    ] as customFooterType,
    kpiData: data?.fuelEuGHGIntensity,
  };

  const WellToWakeCardProps: StatusKpiCardProps = {
    title: 'Well to Wake (WtW) Summary\n(gCO₂eq/MJ)',
    titleAlignment: 'center',
    footerType: [
      {
        title: 'Well to Tank',
        value: data?.wellToTank,
        unitLabel: '',
      },
      {
        title: 'Tank to Wake',
        value: data?.tankToWake,
        unitLabel: '',
      },
      {
        title: 'Wind Reward Factor',
        value: data?.windRewardFactor,
        unitLabel: '',
      },
    ] as customFooterType,
    kpiData: emptyKpiData,
    footerUnits: TEXT.FORMULAS.EEOI,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };

  const ComplianceBalanceAgainstFleetProps: StatusKpiCardProps = {
    title: 'Compliance Balance Against Fleet',
    units: '',
    footerUnits: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
    sectionVisibility: { footer: true },
    footerType: [
      {
        title: 'Vessel',
        value: formatNumber(data?.complianceBalanceFleet.secondaryValue, 0),
        unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
      },
      {
        title: 'Fleet',
        value: formatNumber(data?.complianceBalanceFleet.tertiaryValue, 0),
        unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
      },
    ] as customFooterType,
    kpiData: data?.complianceBalanceFleet,
  };

  const GhgMrvEmissionProps: StatusKpiCardProps = {
    title: 'Greenhouse Gas (GHG) Emission',
    titleAlignment: 'center',
    footerType: (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{ fontSize: typography.fontSize['10'], lineHeight: '100%' }}
        >
          {formatNumber(data?.ghgMrvTotal, 2)}
        </Typography>
        <Box fontSize={'medium'} sx={{ color: 'gray' }}>
          tCO₂eq
        </Box>
        <Box sx={{ mt: 3 }}>MRV, Tank to Wake</Box>
      </Box>
    ),
    kpiData: {
      primaryValue: '',
      status: data?.ghgMrvTotal ? Status.graph : Status.none,
    },
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };

  const ghgMrvSummaryChartDomain =
    VesselKPIDataMapper.toGhgMrvSummaryChartDomain(data ?? undefined);
  const GhgMrvSummaryProps: StatusKpiCardProps = {
    title: 'GHGmrv Summary',
    titleAlignment: 'center',
    footerType: (
      <PieChartStraightAngleMui
        data={ghgMrvSummaryChartDomain.data}
      ></PieChartStraightAngleMui>
    ),
    kpiData: {
      primaryValue: '',
      status: ghgMrvSummaryChartDomain.status,
    },
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };

  const euKPIData = [
    hasPlannedValue ? EuaAgainstPlannedProps : EuaNoPlannedProps,
    EuaAgainstFleetProps,
  ];
  const fuelEuKPIData = [
    GHGIntensityProps,
    ComplianceBalanceAgainstFleetProps,
    WellToWakeCardProps,
  ];

  const euMrvKpiData = ghgMrvPart2FeatureEnabled
    ? [GhgMrvEmissionProps, GhgMrvSummaryProps]
    : [];

  const { setCardData } = useContext(ExcelExportContext);
  const section = useContext(sectionContext);

  useEffect(() => {
    setCardData(euKPIData, section);
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={euKPIData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={euKPIData} />;
  }

  return (
    <Box sx={{ marginTop: 1 }}>
      <KpiCardGridContainer>
        <RenderKPIGroup title={'EU ETS'} kpiData={euKPIData} />

        <KpiCardDivider />

        <RenderKPIGroup title={'EU MRV'} kpiData={euMrvKpiData} />
      </KpiCardGridContainer>

      <KpiCardGridContainer>
        <RenderKPIGroup title={'FUEL EU'} kpiData={fuelEuKPIData} />
      </KpiCardGridContainer>
      <Box display='flex' flexDirection='column' sx={{ marginTop: 2 }}>
        <EUAMuiChart />
      </Box>
    </Box>
  );
};
