import { Fleet, EeoiValues, FuelEuGhgIntensity } from '_gql/graphql';
import { ServiceResult } from 'shared/models/serviceResult.model';
import { FleetCiiRatingsDataMapper } from '../mappers/fleet-cii-ratings.mapper';
import { IFleetCiiDomain } from '../models/vessel-cii-rating.model';
import {
  useGetFleetCiiMonthlyRatings,
  useGetFleetEeoiChartData,
  useGetFleetKpiData,
  useGetFleetUtilizationData,
  useGetFleetFuelEuGhgIntensityChartData,
} from '../repositories/fleet.repository';
import { IFleetUtilization } from '../models/fleet.model';
import { FleetEeoiDataMapper } from '../mappers/fleet-eeoi.mapper';
import { FleetFuelGHGIntensityDataMapper } from '../mappers/fleet-fuelGHGIntensity.mapper';
import { fleetCIIHistoricalType } from '../view-models/cii-ratings-chart.viewModel';
import { VesselMapper } from '../mappers/vessel.mapper';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { ApolloError } from '@apollo/client';

export const useGetFleetKpis = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
): ServiceResult<Fleet> => {
  try {
    const response = useGetFleetKpiData(dateRange, vesselImos);
    const loading = response?.loading;
    const error = response?.error;
    const data = response?.data?.['fleet'] ?? undefined;

    return {
      data: data as unknown as Fleet,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useGetFleetEeoiChart = (vesselImos: string[] = []) => {
  try {
    const response = useGetFleetEeoiChartData(
      new DateRange(new UTCDate().startOfYear, new UTCDate().endOfYear),
      vesselImos
    );
    const loading = response?.loading;
    if (loading) return ApolloResults.loadingResult;

    const error = response?.error;
    if (error) return ApolloResults.errorResult(error);
    if (!response.data) return ApolloResults.emptyResult;

    const fleet = (response?.data?.['fleet'] as any) ?? undefined;
    const eeoiValues = (fleet?.eeoiMonthlyValues ?? undefined) as EeoiValues[];
    const data = FleetEeoiDataMapper.toDomain(eeoiValues);
    return {
      data: data,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: [],
      error: undefined,
    };
  }
};

export const useGetFleetCII = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
): ServiceResult<IFleetCiiDomain> => {
  try {
    const response: any = useGetFleetCiiMonthlyRatings(dateRange, vesselImos);

    const loading = response?.loading;
    if (loading || !response.data)
      return loadingResultObject as ServiceResult<IFleetCiiDomain>;

    const error = response?.error;
    if (error)
      return errorResultObject(error) as ServiceResult<IFleetCiiDomain>;

    const fleetCIIRatings = response?.data?.fleet?.ciiMonthlyRatings ?? [];
    const categorizedData =
      FleetCiiRatingsDataMapper.categorizingByYear(fleetCIIRatings);

    const ytdDomainData = FleetCiiRatingsDataMapper.toDomain(
      categorizedData[fleetCIIHistoricalType.YTD]
    );
    const lastYAndYtdDomainData = FleetCiiRatingsDataMapper.toDomain(
      categorizedData[fleetCIIHistoricalType.LAST_YEAR_AND_YTD]
    );

    const data = {
      [fleetCIIHistoricalType.YTD]: ytdDomainData,
      [fleetCIIHistoricalType.LAST_YEAR_AND_YTD]: [
        ...lastYAndYtdDomainData,
        ...ytdDomainData,
      ],
    };
    return {
      data: data,
      loading,
      error,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useGetFleetUtilization = (
  dateRange: DateRange | null,
  vesselImos?: string[]
) => {
  const response = useGetFleetUtilizationData(dateRange, vesselImos);
  const loading = response?.loading;
  const error = response?.error;
  const data = (response?.data?.['fleet'] as any) ?? undefined;
  const fleetUtilization = (data?.fleetUtilization ??
    undefined) as IFleetUtilization;

  const vesselData = (response?.data?.['vessels'] as any) ?? undefined;
  const vesselUtilization = VesselMapper.fromFleetUtilizationDTO(vesselData);

  let empty = true;
  if (fleetUtilization) {
    const atSeaCount = fleetUtilization['atSea']?.count ?? 0;
    const inPortCount = fleetUtilization['inPort']?.count ?? 0;
    const ballastCount = fleetUtilization['ballast']?.count ?? 0;
    const ladenCount = fleetUtilization['laden']?.count ?? 0;
    const idleCount = fleetUtilization['idle']?.count ?? 0;

    const categoryCount =
      atSeaCount + inPortCount + ballastCount + ladenCount + idleCount;

    empty = categoryCount === 0;
  }

  return {
    data: { fleetUtilization, vesselUtilization },
    loading,
    error,
    empty,
  };
};

export const useGetFleetFuelEuGhgIntensityChart = (
  dateRange: DateRange | null,
  vesselImos: string[] = []
) => {
  const response = useGetFleetFuelEuGhgIntensityChartData(
    dateRange,
    vesselImos
  );
  const loading = response?.loading;
  if (loading) return ApolloResults.loadingResult;

  const error = response?.error;
  if (error) return errorResult(error);
  if (!response.data) return ApolloResults.emptyResult;

  const fleet = (response?.data?.['fleet'] as any) ?? undefined;
  const ghgIntensityValues = (fleet?.fuelGHGIntensity?.ghgIntensity ??
    undefined) as FuelEuGhgIntensity[];
  const data = FleetFuelGHGIntensityDataMapper.toDomain(ghgIntensityValues);
  return {
    data: data,
    loading,
    error,
  };
};

const apolloResult = <T>(
  data: T,
  loading: boolean,
  error: ApolloError | undefined
) => {
  return {
    data: data,
    loading: loading,
    error: error,
  };
};

const loadingResult = apolloResult([], true, undefined);
const loadingResultObject = apolloResult({}, true, undefined);
const emptyResult = apolloResult([], false, undefined);
const emptyResultObject = apolloResult({}, false, undefined);
const errorResult = (error: ApolloError) => apolloResult([], false, error);
const errorResultObject = (error: ApolloError) =>
  apolloResult({}, false, error);

export const ApolloResults = {
  loadingResult,
  loadingResultObject,
  emptyResult,
  emptyResultObject,
  errorResult,
  errorResultObject,
};
