import { FuelEuGhgIntensity } from '_gql/graphql';
export class FleetFuelGHGIntensityDataMapper {
  static toDomain(data: Array<FuelEuGhgIntensity>) {
    return toDataPoint(data);
  }
}

const toDataPoint = (data: Array<FuelEuGhgIntensity>) => {
  data = data.filter((data) => data.date);
  return (
    data?.map((info: FuelEuGhgIntensity) => ({
      date: info.date,
      intensityTarget: info.intensityTarget,
      intensity: info.intensity,
      complianceBalance: info.complianceBalance,
      fuelEUPenalty: info.fuelEUPenalty,
    })) ?? []
  );
};
